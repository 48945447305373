import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const DunkinConnectionPage = () => (
	<Layout headerColor='yellow'>
      <SEO title="Home" />

      <h1>The Dunkin' Connection Coming Soon</h1>
  	</Layout>
)

export default DunkinConnectionPage;